import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { width } from "@mui/system";
import em from "src/utils/em";
import space from "src/utils/space";
import toAlign, { Align } from "./utils/toAlign";
import mq from "src/utils/mq";

interface BaseProps {
  width?: string;
}

export const Base = styled.div<BaseProps>`
  position: relative;
  width: ${({ width }) => (width ? width : "100%")};
`;

interface BaseHeaderProps {
  variant: "standard" | "form" | "thin";
}

/* TODO - Refine, requre to adjust for form elements aligning to left navigation */
const toVariant = (variant: BaseHeaderProps["variant"]): string => {
  switch (variant) {
    case "form":
      return "1em";
    case "thin":
      return "0em";
    default:
      return "1.5em";
  }
};

export const BaseHeader = styled.header<BaseHeaderProps>`
  position: relative;
  padding-left: 0;
  padding-right: 0;
  padding-top: ${space(3)};
  padding-bottom: ${({ variant }) => toVariant(variant)}};
`;

export const BaseHeaderGroupHeading = styled.div`
  position: relative;
`;

export const BaseHeaderLead = styled(Typography)`
  margin-top: ${space(1)};
`;

interface BaseHeaderGroupBodyProps {
  hasLead?: boolean;
}

export const BaseHeaderGroupBody = styled.div<BaseHeaderGroupBodyProps>`
  position: relative;
  padding-top: ${({ hasLead }) => (hasLead ? space(3) : space(5))};
`;

BaseHeaderGroupBody.defaultProps = {
  hasLead: false,
};

interface BaseBodyProps {
  variant: "standard" | "narrow" | "stretch";
}

export const BaseBody = styled.div<BaseBodyProps>`
  position: relative;
  padding-top: 0;
  padding-bottom: 1em;
  max-width: ${({ variant }) => (variant === "narrow" ? em(525) : "none")};
  margin-left: ${({ variant }) => (variant === "stretch" ? em(-16) : "0")};
  margin-right: ${({ variant }) => (variant === "stretch" ? em(-16) : "0")};
  & > :first-child {
    margin-top: 0;
  }
  & > :last-child {
    margin-bottom: 0;
  }
`;

interface BaseFooterProps {
  align: Align;
  variant: "standard" | "narrow" | "stretch";
}

export const BaseFooter = styled.footer<BaseFooterProps>`
  position: relative;
  padding: ${space()} 0 ${space(4)} 0;
  display: flex;
  justify-content: ${({ align }) => toAlign(align)};
  max-width: ${({ variant }) => (variant === "narrow" ? em(525) : "none")};
  margin-left: ${({ variant }) => (variant === "stretch" ? em(-16) : "0")};
  margin-right: ${({ variant }) => (variant === "stretch" ? em(-16) : "0")};
`;

export const BaseLead = styled.div`
  position: relative;
  padding-top: ${em(40)};
  padding-bottom: ${em(40)};
`;

export const BaseBrand = styled.div`
  position: relative;
  margin-top: ${space(2)};
  width: 100%;
  max-width: ${em(100)};
  max-height: ${em(50)};
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${em(5)};

  ${mq("md")} {
    max-width: ${em(200)};
    max-height: ${em(100)};
    margin-top: 0;
  }
`;

export const BaseImage = styled.img`
  position: relative;
  width: 100%;
  max-width: ${em(100)};
  max-height: ${em(50)};
  object-fit: contain;
  margin: auto;

  ${mq("md")} {
    max-width: ${em(200)};
    max-height: ${em(100)};
    margin-top: 0;
  }
`;
