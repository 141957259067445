import type { State } from "xstate";
import { useSelector } from "@xstate/react";
import { useRouter } from "next/router";
import { ComponentType, ReactNode, useEffect } from "react";
import { useApp } from "src/contexts/AppContext";
import { AppEvent, AppContext, UserType } from "src/machines/appMachine";
import { NextPageWithLayout } from "pages/_app";
import isDefined from "src/utils/isDefined";
import { ParsedUrlQuery } from "querystring";

const isAuthSelector = (state: State<AppContext, AppEvent, any>): boolean =>
  state.matches({ authentication: { init: "authenticated" } });

const isUserDetailsLoadedSelector = (
  state: State<AppContext, AppEvent, any>
): boolean =>
  state.context.auth.userType === "Agent" ||
  state.context.auth.userType === "Artist" ||
  state.context.auth.userType === "CastingDirector"
    ? state.context.userDetailsLoaded
    : true;

const isUserTypeSelector = (
  { context: { auth } }: State<AppContext, AppEvent, any>,
  userType: UserType
): boolean => auth.userType === userType;

const isUserTypesSelector = (
  { context: { auth } }: State<AppContext, AppEvent, any>,
  userTypes: UserType[]
): boolean => userTypes.indexOf(auth.userType) !== -1;

const isAdminSelector = ({
  context: { auth },
}: State<AppContext, AppEvent, any>): boolean => auth.isTeamAdmin;

interface InvalidRouteParameter {
  parameter: string;
  values: string[];
}

interface WithProtectedProps {
  userType?: UserType;
  userTypes?: UserType[];
  redirect?: string;
  teamAdminRequired?: boolean;
  invalidRouteParameters?: InvalidRouteParameter[];
  fallbackRoute?: string;
}

const withProtected =
  (
    {
      userType,
      userTypes,
      redirect,
      teamAdminRequired,
      invalidRouteParameters,
      fallbackRoute,
    }: WithProtectedProps = {
      userType: "CastingDirector",
      userTypes: ["CastingDirector"],
      teamAdminRequired: false,
      redirect: "/login",
      invalidRouteParameters: [],
      fallbackRoute: "/",
    }
  ) =>
  <T extends JSX.IntrinsicAttributes & { children?: ReactNode }>(
    Component: NextPageWithLayout
  ) => {
    // Inject layout and provider if defined.
    const fn = (hocProps: T) => {
      const router = useRouter();
      const { appService } = useApp();
      const isAuthenticated = useSelector(appService, isAuthSelector);

      // An undefined or null userType means that the page is available for all user types
      const isValidUserType =
        userType != null
          ? useSelector(appService, (state) =>
              // @ts-ignore
              isUserTypeSelector(state, userType!)
            )
          : userTypes != null && userTypes.length > 0
          ? useSelector(appService, (state) =>
              // @ts-ignore
              isUserTypesSelector(state, userTypes!)
            )
          : true;

      const hasRequiredPermission =
        teamAdminRequired === true
          ? // @ts-ignore
            useSelector(appService, (state) => isAdminSelector(state))
          : true;

      useEffect(() => {
        if (!isAuthenticated) {
          // If we are not authenticated, redirect to the defined page
          router.replace(redirect!);
          return;
        }

        // Don't check this unless the router is ready, that way we avoid the "flash" of the unauthorized page
        if (!router.isReady) {
          return;
        }

        if (
          invalidRouteParameters != null &&
          invalidRouteParameters.length > 0 &&
          fallbackRoute != null
        ) {
          const query = router.query;
          for (let i = 0; i < invalidRouteParameters.length; i++) {
            const p = invalidRouteParameters[i];

            // If the route contains any of the invalid route parameter values, push the user to the fallback route
            if (
              isDefined(query[p.parameter]) &&
              query[p.parameter] != null &&
              p.values.find((v) => query[p.parameter] === v) != null
            ) {
              // first construct a new query object without the invalid parameter
              const replacementQuery: ParsedUrlQuery = {};
              Object.keys(query).forEach((k) => {
                if (k !== p.parameter) {
                  replacementQuery[k] = query[k];
                }
              });
              // We do not have the required route parameter, redirect to the defined fallback page
              router.replace({
                pathname: fallbackRoute!,
                query: replacementQuery,
              });
              return;
            }
          }
        }

        // Invalid user type/unauthorized is a static redirect
        if (isAuthenticated && (!isValidUserType || !hasRequiredPermission)) {
          router.replace("/unauthorized");
          return;
        }
      }, [router, isAuthenticated]);

      return <Component {...hocProps} />;
    };

    // Re-map Layout for _app
    fn.Layout = Component.Layout;
    fn.Provider = Component.Provider;
    fn.SecondaryProvider = Component.SecondaryProvider;
    return fn;
  };

export default withProtected;
